var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lock-history"},[_c('div',{staticClass:"lock-history__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}})],1),_c('div',{staticClass:"lock-history__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"287px"},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"date",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.dateCreated)))])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [(row.locked)?_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Blocked'))+" ")]):_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('Unblocked'))+" ")])]}},{key:"unblocked",fn:function(ref){
var row = ref.row;
return [(row.until != null)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$utils.getDate(row.until))+" ")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.until)))])]):(row.locked === false)?_c('span'):_c('span',[_vm._v(_vm._s(_vm.$t('Permanently')))])]}},{key:"initiator",fn:function(ref){
var row = ref.row;
return [(row.adminId)?_c('span',{staticClass:"text--secondary"},[(typeof row.adminId === 'number')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(row.adminId)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Administrator ID')))])]):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(_vm.$t('System'))+" ")])]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t(("const." + (_vm.getLockType(row.userLockType)))))+" ")]}},{key:"reason",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t(row.comment))+" ")]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('Pagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }