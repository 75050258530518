export default [
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Code',
    },
    cell: {
      type: 'text',
      value: (e) => e.code,
    },
  },
  {
    requiredAdminStatus: 1,
    width: 13,
    header: {
      type: 'text',
      caption: 'Number',
    },
    cell: {
      type: 'slot',
      name: 'number',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Code type',
    },
    cell: {
      type: 'slot',
      name: 'codeType',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Created',
    },
    cell: {
      type: 'slot',
      name: 'dateCreated',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Expiry',
    },
    cell: {
      type: 'slot',
      name: 'dateExpiry',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Used',
    },
    cell: {
      type: 'slot',
      name: 'isUsed',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: '2FA method',
    },
    cell: {
      type: 'slot',
      name: 'twoFactorMethod',
    },
  },
];
