//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import sha256 from 'js-sha256';

export default {
  data() {
    return {
      newPassword: '',
      rules: {
        passwordLength: (value) => (value.length >= 8) || 'Field must be at least 8 characters',
      },
    };
  },

  computed: {
    ...mapState('user', ['user']),
  },

  methods: {
    ...mapActions('common', [
      'setSuccessNotification',
      'setErrorNotification',
      'setGeneralProgress',
      'confirmAction',
    ]),
    ...mapActions('user', ['changeUserPassword']),

    change() {
      this.confirmAction({
        text: 'Are you sure?',
        callback: async () => {
          this.setGeneralProgress(true);
          try {
            const { newPassword } = this;
            const { id } = this.user;
            const password = sha256(sha256(newPassword));
            await this.changeUserPassword({
              userId: id,
              newPassword: password,
            });
            this.setSuccessNotification('Password changed');
            this.newPassword = '';
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
        },
      });
    },
  },
};
