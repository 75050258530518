var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"history-nfa"},[_c('div',{staticClass:"history-nfa__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}})],1),_c('div',{staticClass:"history-nfa__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"287px"},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"service",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.getNfaServiceTypeList(row.nfaServiceType)))+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.enabled)?_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('Enabled'))+" ")]):_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Disabled'))+" ")])]}},{key:"ip",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ipAddress)+" ")]}},{key:"date",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$utils.getTime(row.dateCreated))+" ")])]}},{key:"action",fn:function(){return [_vm._v(" – ")]},proxy:true},{key:"admin",fn:function(ref){
var row = ref.row;
return [(row.adminId)?_c('span',[_vm._v(" "+_vm._s(_vm.getAdmin(row.adminId))+" ")]):_c('span',[_vm._v("–")])]}},{key:"sections",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getNfaSectionTypeList(row.nfaSectionTypeList))+" ")]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('Pagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }