//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import UserSecurityLocks from '~/views/UserSecurity/UserSecurityLocks/UserSecurityLocks.vue';
import UserSecurityLockHistory from '~/views/UserSecurity/UserSecurityLockHistory/UserSecurityLockHistory.vue';
import UserSecurityIpWhitelist from '~/views/UserSecurity/UserSecurityIpWhitelist/UserSecurityIpWhitelist.vue';
import UserSecurityHistoryNfa from '~/views/UserSecurity/UserSecurityHistoryNfa/UserSecurityHistoryNfa.vue';
import UserSecurityLog from '~/views/UserSecurity/UserSecurityLog/UserSecurityLog.vue';
import UserSecurityActiveFa from '~/views/UserSecurity/UserSecurityActiveFa/UserSecurityActiveFa.vue';
import UserSecurity2FaCodes from '~/views/UserSecurity/UserSecurity2FaCodes/Table.vue';
import UserSecurityChangePassword from '~/views/UserSecurity/UserSecurityChangePassword/UserSecurityChangePassword.vue';

export default {
  components: {
    UserSecurityLocks,
    UserSecurityLockHistory,
    UserSecurityIpWhitelist,
    UserSecurityHistoryNfa,
    UserSecurityLog,
    UserSecurityActiveFa,
    UserSecurityChangePassword,
    UserSecurity2FaCodes,
  },

  async mounted() {
    const { id } = this.$route.params;
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.silentLoadUser(id),
      this.loadUserSecurityLockHistory(),
      this.loadUserSecurityIpWhiteList(),
      this.loadUserSecurityHistoryNfa(),
      this.loadUserSecurityLog(),
      this.loadUserSecurity2FaCodes(),
      // this.loadP2pArbitratorsList(),
    ]);

    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('user', ['silentLoadUser']),
    ...mapActions('userSecurityLockHistory', {
      loadUserSecurityLockHistory: 'loadData',
    }),
    ...mapActions('userSecurityIpWhiteList', {
      loadUserSecurityIpWhiteList: 'loadData',
    }),
    ...mapActions('userSecurityHistoryNfa', {
      loadUserSecurityHistoryNfa: 'loadData',
    }),
    ...mapActions('userSecurityLog', {
      loadUserSecurityLog: 'loadData',
    }),
    ...mapActions('userSecurity2FaCodes', {
      loadUserSecurity2FaCodes: 'loadData',
    }),
    // ...mapActions('p2pArbitratorsList', {
    //   loadP2pArbitratorsList: 'loadData',
    // }),
  },
};
