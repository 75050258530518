import constant from '~/const';

const { USER_LOCK_TYPE_LIST, USER_LOCK_TYPE_LIST_P2PAY } = constant.users;

const blockList = !process.env.VUE_APP_EXCLUDE_FEATURE.includes('transactions-old')
  ? USER_LOCK_TYPE_LIST.map((e) => ({
    caption: e.caption,
    action: 'block',
    payload: e.id,
  }))
  : USER_LOCK_TYPE_LIST_P2PAY.map((e) => ({
    caption: e.caption,
    action: 'block',
    payload: e.id,
  }));

export default blockList;
