//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import BlockAlert from '~/components/users/BlockAlert.vue';
import UsersLock from '~/views/Users/modals/UsersLock.vue';
import constant from '~/const';
import actionsConfig from './actionsConfig';

export default {
  components: { BlockAlert, UsersLock },

  data() {
    return {
      loader: false,
      usersLock: {
        modal: false,
        type: 0,
      },

      actionsConfig,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('user', ['user']),

    userLockList() {
      const { userLockList } = this.user;
      if (userLockList && userLockList.length) {
        return userLockList;
      }
      return [];
    },
  },

  methods: {
    ...mapActions('common', [
      'confirmAction',
      'setSuccessNotification',
      'setErrorNotification',
      'setGeneralProgress',
    ]),
    ...mapActions('users', {
      usersLockAction: 'usersLock',
      usersBlockAction: 'usersBlock',
      usersUnlockAction: 'usersUnlock',
      usersUnblockAction: 'usersUnblock',
    }),
    ...mapActions('user', ['silentLoadUser']),
    ...mapActions('userSecurityLockHistory', {
      loadUserSecurityLockHistory: 'loadData',
    }),

    unlockUser(userId, type) {
      this.confirmAction({
        text: 'Are you sure you want to unlock user?',
        hasPrompt: true,
        promptRequired: true,
        callback: async ({ prompt }) => {
          this.loader = true;
          this.setGeneralProgress(true);
          try {
            if (type === constant.users.USER_LOCK_TYPE.GENERAL) {
              await this.usersUnblockAction({
                userIds: [userId],
                comment: prompt,
              });
            } else {
              await this.usersUnlockAction({
                userIds: [userId],
                comment: prompt,
                type,
              });
            }
            await Promise.all([
              this.silentLoadUser(userId),
              this.loadUserSecurityLockHistory(),
            ]);
            this.setSuccessNotification('User unlocked');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.loader = false;
          this.setGeneralProgress(false);
        },
      });
    },

    confirmBlock(type) {
      this.usersLock.modal = true;
      this.usersLock.type = type;
    },

    async block({ comment, blockTime }) {
      const { id } = this.user;
      const { type } = this.usersLock;
      this.loader = true;
      this.setGeneralProgress(true);
      try {
        switch (type) {
          case constant.users.USER_LOCK_TYPE.GENERAL:
            await this.usersBlockAction({
              userIds: [id],
              comment,
            });
            break;
          case constant.users.USER_LOCK_TYPE.ALL_WITHDRAWAL:
            await this.usersBlockAction({
              userIds: [id],
              comment,
              types: [constant.users.USER_LOCK_TYPE.WITHDRAWAL, constant.users.USER_LOCK_TYPE.P2P_SELL_CRYPTO],
            });
            break;
          default:
            await this.usersLockAction({
              userIds: [id],
              comment,
              blockTime,
              type,
            });
            break;
        }
        await Promise.all([
          this.silentLoadUser(id),
          this.loadUserSecurityLockHistory(),
        ]);
        this.setSuccessNotification('User locked');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.loader = false;
      this.setGeneralProgress(false);
    },
  },
};
