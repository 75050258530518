//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Pagination from '~/components/Pagination.vue';
import GetReport from '~/components/GetReport.vue';

import constant from '~/const';
import tableConfig from './tableConfig';
import Filters from './UserSecurityLogFilters.vue';

export default {
  components: {
    Filters,
    Pagination,
    GetReport,
  },

  data() {
    return {
      loader: false,
      tableConfig,
      constant,
      loadingLocation: false,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapGetters('login', ['getAdminById']),
    ...mapState('userSecurityLog', ['page', 'pages', 'data', 'sort']),
    ...mapGetters('userSecurityLog', ['requestData']),
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setSuccessNotification', 'setGeneralProgress']),
    ...mapActions('userSecurityLog', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.loader = true;
      this.setGeneralProgress(true);
      await this.loadDataAction(data);
      this.loader = false;
      this.setGeneralProgress(false);
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    getAdmin(id) {
      const admin = this.getAdminById(id);
      if (admin) {
        return admin.email;
      }
      return '';
    },

    currentLocation(row) {
      const { ipAddressData } = row;
      if (ipAddressData) {
        return `${ipAddressData.country}, ${ipAddressData.city}`;
      }
      return this.$t('Location unknown');
    },
  },
};
