var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"section__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages},on:{"input":_vm.setPage}})],1),_c('div',{staticClass:"section__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px"},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"codeType",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getCaption(_vm.constant.user.NFA_CODE_TYPE_LIST, row.codeType))+" ")]}},{key:"number",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.number))])]}},{key:"dateCreated",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.dateCreated)))])]}},{key:"dateExpiry",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.dateExpiry)))])]}},{key:"isUsed",fn:function(ref){
var row = ref.row;
return [(row.isUsed)?_c('span',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.$t('Yes')))]):_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t('No')))])]}},{key:"twoFactorMethod",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getCaption(_vm.constant.user.TWO_FACTOR_METHOD_TYPE_LIST, row.twoFactorMethod))+" ")]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }